<template>
    <main>
      <Header />
      <header class="uui-section_heroheader05">
        <div class="uui-page-padding-8">
          <div class="uui-container-large-7">
            <div class="uui-padding-vertical-xhuge-11">
              <div class="w-layout-grid uui-heroheader05_component">
                <div class="uui-heroheader05_content">
                  <div class="uui-button-row-7">
                    <a href="#" class="uui-badge-group is-primary w-inline-block">
                      <div class="badge-group_text-wrapper">
                        <div><strong>MONITOREAMOS CADA LATIDO</strong></div>
                      </div>
                    </a>
                  </div>
                  <div class="uui-space-small-6"></div>
                  <h1 class="uui-heading-xlarge"><strong>iDUAM G-50</strong></h1>
                  <div class="uui-pricing01_top-right">
                    <div class="uui-pricing01_top-right-price"><span class="uui-pricing01_top-right-text">Ahora por solo
                      </span></div>
                    <div class="uui-pricing01_top-right-price"><strong>$89.990</strong></div>
                    <div class="uui-pricing01_top-right-price"
                      style="color: #98a2b3; font-size: 1.3rem; font-weight: 300; text-decoration: line-through;">Antes
                      $99.990</div>
                  </div>
                  <div class="uui-space-small-6"></div>
                  <div class="uui-text-size-medium">La <strong>iDUAM G-50</strong> es mucho más que una smartband, es tu
                    aliada para el cuidado de la salud. </div>
                    <div style="display:flex; flex-wrap: wrap;">
                  <div class="uui-pricing01_feature-list _2">
                    <div id="w-node-_46f993c2-4fbc-0fb2-e7c8-aacc8bbeb4fd-cc03f7ee" class="uui-pricing01_feature">
                      <div class="uui-pricing01_icon-wrapper _2">
                        <div class="uui-icon-1x1-xsmall-7 w-embed"><svg width="24" height="24" viewbox="0 0 24 24"
                            fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
                              fill="#D1FADF"></path>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z"
                              fill="#12B76A"></path>
                          </svg></div>
                      </div>
                      <div class="uui-text-size-medium-7"><strong>Ritmo Cardíaco</strong></div>
                    </div>
                    <div id="w-node-_46f993c2-4fbc-0fb2-e7c8-aacc8bbeb504-cc03f7ee" class="uui-pricing01_feature">
                      <div class="uui-pricing01_icon-wrapper _2">
                        <div class="uui-icon-1x1-xsmall-7 w-embed"><svg width="24" height="24" viewbox="0 0 24 24"
                            fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
                              fill="#D1FADF"></path>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z"
                              fill="#12B76A"></path>
                          </svg></div>
                      </div>
                      <div class="uui-text-size-medium-7"><strong>Presión Arterial</strong></div>
                    </div>
                    <div id="w-node-_46f993c2-4fbc-0fb2-e7c8-aacc8bbeb50b-cc03f7ee" class="uui-pricing01_feature">
                      <div class="uui-pricing01_icon-wrapper _2">
                        <div class="uui-icon-1x1-xsmall-7 w-embed"><svg width="24" height="24" viewbox="0 0 24 24"
                            fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
                              fill="#D1FADF"></path>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z"
                              fill="#12B76A"></path>
                          </svg></div>
                      </div>
                      <div class="uui-text-size-medium-7"><strong>Saturación de Oxígeno (SpO2)</strong></div>
                    </div>
                    <div id="w-node-_46f993c2-4fbc-0fb2-e7c8-aacc8bbeb512-cc03f7ee" class="uui-pricing01_feature">
                      <div class="uui-pricing01_icon-wrapper _2">
                        <div class="uui-icon-1x1-xsmall-7 w-embed"><svg width="24" height="24" viewbox="0 0 24 24"
                            fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
                              fill="#D1FADF"></path>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z"
                              fill="#12B76A"></path>
                          </svg></div>
                      </div>
                      <div class="uui-text-size-medium-7"><strong>Temperatura Corporal</strong></div>
                    </div>
                    <div id="w-node-_46f993c2-4fbc-0fb2-e7c8-aacc8bbeb519-cc03f7ee" class="uui-pricing01_feature">
                      <div class="uui-pricing01_icon-wrapper _2">
                        <div class="uui-icon-1x1-xsmall-7 w-embed"><svg width="24" height="24" viewbox="0 0 24 24"
                            fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
                              fill="#D1FADF"></path>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z"
                              fill="#12B76A"></path>
                          </svg></div>
                      </div>
                      <div class="uui-text-size-medium-7"><strong>Electrocardiograma (ECG)</strong></div>
                    </div>
                  </div>
                  <div class="uui-pricing01_feature-list _2">
                    <div id="w-node-_46f993c2-4fbc-0fb2-e7c8-aacc8bbeb4fd-cc03f7ee" class="uui-pricing01_feature">
                      <div class="uui-pricing01_icon-wrapper _2">
                        <div class="uui-icon-1x1-xsmall-7 w-embed"><svg width="24" height="24" viewbox="0 0 24 24"
                            fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
                              fill="#D1FADF"></path>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z"
                              fill="#12B76A"></path>
                          </svg></div>
                      </div>
                      <div class="uui-text-size-medium-7"><strong>Lectura de Glucosa</strong></div>
                    </div>
                    <div id="w-node-_46f993c2-4fbc-0fb2-e7c8-aacc8bbeb504-cc03f7ee" class="uui-pricing01_feature">
                      <div class="uui-pricing01_icon-wrapper _2">
                        <div class="uui-icon-1x1-xsmall-7 w-embed"><svg width="24" height="24" viewbox="0 0 24 24"
                            fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
                              fill="#D1FADF"></path>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z"
                              fill="#12B76A"></path>
                          </svg></div>
                      </div>
                      <div class="uui-text-size-medium-7"><strong>Mediciones de Colesterol</strong></div>
                    </div>
                    <div id="w-node-_46f993c2-4fbc-0fb2-e7c8-aacc8bbeb50b-cc03f7ee" class="uui-pricing01_feature">
                      <div class="uui-pricing01_icon-wrapper _2">
                        <div class="uui-icon-1x1-xsmall-7 w-embed"><svg width="24" height="24" viewbox="0 0 24 24"
                            fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
                              fill="#D1FADF"></path>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z"
                              fill="#12B76A"></path>
                          </svg></div>
                      </div>
                      <div class="uui-text-size-medium-7"><strong>Ácido Úrico</strong></div>
                    </div>
                    <div id="w-node-_46f993c2-4fbc-0fb2-e7c8-aacc8bbeb512-cc03f7ee" class="uui-pricing01_feature">
                      <div class="uui-pricing01_icon-wrapper _2">
                        <div class="uui-icon-1x1-xsmall-7 w-embed"><svg width="24" height="24" viewbox="0 0 24 24"
                            fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
                              fill="#D1FADF"></path>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z"
                              fill="#12B76A"></path>
                          </svg></div>
                      </div>
                      <div class="uui-text-size-medium-7"><strong>Triglicéridos</strong></div>
                    </div>
                    <div id="w-node-_46f993c2-4fbc-0fb2-e7c8-aacc8bbeb519-cc03f7ee" class="uui-pricing01_feature">
                      <div class="uui-pricing01_icon-wrapper _2">
                        <div class="uui-icon-1x1-xsmall-7 w-embed"><svg width="24" height="24" viewbox="0 0 24 24"
                            fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
                              fill="#D1FADF"></path>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z"
                              fill="#12B76A"></path>
                          </svg></div>
                      </div>
                      <div class="uui-text-size-medium-7"><strong>Índice de Masa Corporal</strong></div>
                    </div>
                  </div>
                </div>
                  <div class="uui-text-size-medium">Tecnología de Precisión: Equipada con el chip TI 1292 IC, garantiza
                    mediciones más exactas, especialmente en la detección de presión arterial, ideal para personas con
                    hipertensión.</div>
                  <div class="uui-space-large"></div>
                  <div class="uui-button-row-6 is-reverse-mobile-landscape">
                    <div class="uui-button-wrapper-6 max-width-full-mobile-landscape-6">
                      <a href="https://ventasiduam.web.app/finalizar-compra?product_id=3" target="_blank"
                        class="uui-button w-inline-block">
                        <div>COMPRAR</div>
                      </a>
                      <!--                     <a href="#" class="uui-button-secondary-gray w-inline-block">
                        <div>Mas información</div>
                      </a> -->
                    </div>
                  </div>
                </div>
                <div class="uui-heroheader05_image-wrapper"><img src="../../assets/G_50.png" style="    max-width: 500px;" loading="lazy"
                    srcset="/G_50-p-500.png 500w, ../../assets/G_50.png 750w"
                    sizes="(max-width: 750px) 100vw, 750px" alt="iPhone mockup" class="uui-heroheader05_image"></div>
              </div>
                    <div style="display: flex; align-items: center; margin-left: -14px;">
            <a href="https://play.google.com/store/apps/details?id=com.naturalphone.sosclickiduam" target="_blank">
              <img style="width: 200px; cursor: pointer;" src="@/assets/googleplay.png" alt="">
            </a>
            <a href="https://apps.apple.com/cl/app/iduam/id1529934806" target="_blank">
              <img style="width: 160px; cursor: pointer;" src="@/assets/appstore.svg" alt="">
            </a>
        </div>
            </div>
          </div>
        </div>
        <div class="div-block-14"></div>
      </header>
      <section class="uui-section_layout79 gradient">
        <div class="uui-page-padding-7">
          <div class="uui-container-large-6">
            <div class="uui-padding-vertical-xhuge-10">
              <div class="uui-layout79_component">
                <div class="w-layout-grid uui-layout79_content">
                  <div class="uui-layout79_content-left">
                    <div class="uui-heading-subheading-5 light">Características</div>
                    <h2 class="uui-heading-medium-6">¿Por qué elegir la IDUAM G-50?</h2>
                  </div>
                  <div class="uui-layout79_content-right">
                    <div class="uui-text-size-large-6 light">La <strong>iDUAM G-50</strong> es mucho más que una
                      smartband, es tu aliada para el cuidado de la salud. </div>
                  </div>
                </div>
                <div class="w-layout-grid uui-layout79_list">
                  <div class="uui-layout79_item">
                    <div class="uui-icon-featured-outline-large-5"><svg width="100%" height="100%" viewbox="0 0 24 24"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M7.5 4C6.04131 4 4.64236 4.57946 3.61091 5.61091C2.57946 6.64236 2 8.04131 2 9.5C2 10 2.09 10.5 2.22 11H6.3L7.57 7.63C7.87 6.83 9.05 6.75 9.43 7.63L11.5 13L12.09 11.58C12.22 11.25 12.57 11 13 11H21.78C21.91 10.5 22 10 22 9.5C22 8.04131 21.4205 6.64236 20.3891 5.61091C19.3576 4.57946 17.9587 4 16.5 4C14.64 4 13 4.93 12 6.34C11 4.93 9.36 4 7.5 4ZM3 12.5C2.73478 12.5 2.48043 12.6054 2.29289 12.7929C2.10536 12.9804 2 13.2348 2 13.5C2 13.7652 2.10536 14.0196 2.29289 14.2071C2.48043 14.3946 2.73478 14.5 3 14.5H5.44L11 20C12 20.9 12 20.9 13 20L18.56 14.5H21C21.2652 14.5 21.5196 14.3946 21.7071 14.2071C21.8946 14.0196 22 13.7652 22 13.5C22 13.2348 21.8946 12.9804 21.7071 12.7929C21.5196 12.6054 21.2652 12.5 21 12.5H13.4L12.47 14.8C12.07 15.81 10.92 15.67 10.55 14.83L8.5 9.5L7.54 11.83C7.39 12.21 7.05 12.5 6.6 12.5H3Z"
                          fill="currentColor"></path>
                      </svg></div>
                    <div class="uui-space-small-5"></div>
                    <h3 class="uui-heading-xxsmall-6 light"> Monitoreo Electrocardiográfico (ECG)</h3>
                    <div class="uui-space-xxsmall-6"></div>
                    <div class="uui-text-size-medium-6 light">Realiza <strong>electrocardiogramas en cualquier
                        momento</strong> desde la App de iDUAM, donde serán analizados por un algoritmo de inteligencia
                      artificial para una interpretación preventiva.</div>
                  </div>
                  <div class="uui-layout79_item">
                    <div class="uui-icon-featured-outline-large-5"><svg width="100%" height="100%" viewbox="0 0 24 24"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M16.5 2.83008C14.76 2.83008 13.09 3.64008 12 4.90008C10.91 3.64008 9.24 2.83008 7.5 2.83008C4.42 2.83008 2 5.24008 2 8.33008C2 12.1001 5.4 15.1901 10.55 19.8601L12 21.1701L13.45 19.8601C18.6 15.1901 22 12.1001 22 8.33008C22 5.24008 19.58 2.83008 16.5 2.83008ZM12 17.8301V13.8301H9L12 6.83008V10.8301H15"
                          fill="currentColor"></path>
                      </svg></div>
                    <div class="uui-space-small-5"></div>
                    <h3 class="uui-heading-xxsmall-6 light">Ritmo Cardíaco en Tiempo Real</h3>
                    <div class="uui-space-xxsmall-6"></div>
                    <div class="uui-text-size-medium-6 light">Consulta tu <strong>ritmo cardíaco de forma sencilla y
                        precisa</strong> sin necesidad de acudir a un hospital o centro médico.</div>
                  </div>
                  <div class="uui-layout79_item">
                    <div class="uui-icon-featured-outline-large-5"><svg width="100%" height="100%" viewbox="0 0 24 24"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M5 15L4.4 14.5C2.4 12.6 1 11.4 1 9.9C1 8.7 2 7.7 3.2 7.7C3.9 7.7 4.6 8 5 8.5C5.4 8 6.1 7.7 6.8 7.7C8 7.7 9 8.6 9 9.9C9 11.4 7.6 12.6 5.6 14.5L5 15ZM15 4C13.9391 4 12.9217 4.42143 12.1716 5.17157C11.4214 5.92172 11 6.93913 11 8C11 9.06087 11.4214 10.0783 12.1716 10.8284C12.9217 11.5786 13.9391 12 15 12C16.0609 12 17.0783 11.5786 17.8284 10.8284C18.5786 10.0783 19 9.06087 19 8C19 6.93913 18.5786 5.92172 17.8284 5.17157C17.0783 4.42143 16.0609 4 15 4ZM15 10.1C14.443 10.1 13.9089 9.87875 13.5151 9.48492C13.1212 9.0911 12.9 8.55695 12.9 8C12.9 7.44305 13.1212 6.9089 13.5151 6.51508C13.9089 6.12125 14.443 5.9 15 5.9C16.16 5.9 17.1 6.84 17.1 8C17.1 9.16 16.16 10.1 15 10.1ZM15 13C12.33 13 7 14.33 7 17V20H23V17C23 14.33 17.67 13 15 13ZM21.1 18.1H8.9V17C8.9 16.36 12 14.9 15 14.9C17.97 14.9 21.1 16.36 21.1 17V18.1Z"
                          fill="currentColor"></path>
                      </svg></div>
                    <div class="uui-space-small-5"></div>
                    <h3 class="uui-heading-xxsmall-6 light">Control de Presión Arterial</h3>
                    <div class="uui-space-xxsmall-6"></div>
                    <div class="uui-text-size-medium-6 light">Toma tu <strong>presión arterial en segundos</strong> desde
                      la App de iDUAM, con tecnología avanzada para resultados más fiables.</div>
                  </div>
                  <div class="uui-layout79_item">
                    <div class="uui-icon-featured-outline-large-5"><svg width="100%" height="100%" viewbox="0 0 24 24"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M12 4C14.32 4 17.5 10.7 17.5 14.5C17.5 17.53 15.03 20 12 20C8.97 20 6.5 17.53 6.5 14.5C6.5 10.7 9.68 4 12 4ZM12 2C7.86 2 4.5 10.36 4.5 14.5C4.5 18.64 7.86 22 12 22C16.14 22 19.5 18.64 19.5 14.5C19.5 10.36 16.14 2 12 2Z"
                          fill="currentColor"></path>
                      </svg></div>
                    <div class="uui-space-small-5"></div>
                    <h3 class="uui-heading-xxsmall-6 light">Saturación de Oxígeno (SpO2)</h3>
                    <div class="uui-space-xxsmall-6"></div>
                    <div class="uui-text-size-medium-6 light">Mide en tiempo real el <strong>nivel de oxígeno en la
                        sangre</strong>, esencial para evaluar tu estado de salud general y prevenir riesgos.</div>
                  </div>
                  <div class="uui-layout79_item">
                    <div class="uui-icon-featured-outline-large-5"><svg width="100%" height="100%" viewbox="0 0 24 24"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M19 3H14.82C14.4 1.84 13.3 1 12 1C10.7 1 9.6 1.84 9.18 3H5C4.46957 3 3.96086 3.21071 3.58579 3.58579C3.21071 3.96086 3 4.46957 3 5V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H19C19.5304 21 20.0391 20.7893 20.4142 20.4142C20.7893 20.0391 21 19.5304 21 19V5C21 4.46957 20.7893 3.96086 20.4142 3.58579C20.0391 3.21071 19.5304 3 19 3ZM12 3C12.2652 3 12.5196 3.10536 12.7071 3.29289C12.8946 3.48043 13 3.73478 13 4C13 4.26522 12.8946 4.51957 12.7071 4.70711C12.5196 4.89464 12.2652 5 12 5C11.7348 5 11.4804 4.89464 11.2929 4.70711C11.1054 4.51957 11 4.26522 11 4C11 3.73478 11.1054 3.48043 11.2929 3.29289C11.4804 3.10536 11.7348 3 12 3ZM5 15H8.11L9.62 12.15L10.38 17.92L14.07 13.21L15.89 15H19V19H5V15ZM19 13.46H16.53L13.93 10.86L11.44 14.05L10.5 7.08L7.17 13.46H5V5H7V6H17V5H19V6V13.46Z"
                          fill="currentColor"></path>
                      </svg></div>
                    <div class="uui-space-small-5"></div>
                    <h3 class="uui-heading-xxsmall-6 light">Monitoreo Remoto y Reportes</h3>
                    <div class="uui-space-xxsmall-6"></div>
                    <div class="uui-text-size-medium-6 light">Tus datos pueden ser <strong>visualizados en un panel
                        web</strong>, permitiendo el seguimiento a distancia por profesionales de la salud.</div>
                  </div>
                  <div class="uui-layout79_item">
                    <div class="uui-icon-featured-outline-large-5"><svg width="100%" height="100%" viewbox="0 0 24 24"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M12 5C11.0717 5 10.1815 5.36875 9.52513 6.02513C8.86875 6.6815 8.5 7.57174 8.5 8.5C8.5 9.42826 8.86875 10.3185 9.52513 10.9749C10.1815 11.6313 11.0717 12 12 12C12.9283 12 13.8185 11.6313 14.4749 10.9749C15.1313 10.3185 15.5 9.42826 15.5 8.5C15.5 7.57174 15.1313 6.6815 14.4749 6.02513C13.8185 5.36875 12.9283 5 12 5ZM12 7C12.3978 7 12.7794 7.15804 13.0607 7.43934C13.342 7.72064 13.5 8.10218 13.5 8.5C13.5 8.89782 13.342 9.27936 13.0607 9.56066C12.7794 9.84196 12.3978 10 12 10C11.6022 10 11.2206 9.84196 10.9393 9.56066C10.658 9.27936 10.5 8.89782 10.5 8.5C10.5 8.10218 10.658 7.72064 10.9393 7.43934C11.2206 7.15804 11.6022 7 12 7ZM5.5 8C4.83696 8 4.20107 8.26339 3.73223 8.73223C3.26339 9.20107 3 9.83696 3 10.5C3 11.44 3.53 12.25 4.29 12.68C4.65 12.88 5.06 13 5.5 13C5.94 13 6.35 12.88 6.71 12.68C7.08 12.47 7.39 12.17 7.62 11.81C6.89 10.86 6.5 9.7 6.5 8.5C6.5 8.41 6.5 8.31 6.5 8.22C6.2 8.08 5.86 8 5.5 8ZM18.5 8C18.14 8 17.8 8.08 17.5 8.22C17.5 8.31 17.5 8.41 17.5 8.5C17.5 9.7 17.11 10.86 16.38 11.81C16.5 12 16.63 12.15 16.78 12.3C16.94 12.45 17.1 12.58 17.29 12.68C17.65 12.88 18.06 13 18.5 13C18.94 13 19.35 12.88 19.71 12.68C20.47 12.25 21 11.44 21 10.5C21 9.83696 20.7366 9.20107 20.2678 8.73223C19.7989 8.26339 19.163 8 18.5 8ZM12 14C9.66 14 5 15.17 5 17.5V19H19V17.5C19 15.17 14.34 14 12 14ZM4.71 14.55C2.78 14.78 0 15.76 0 17.5V19H3V17.07C3 16.06 3.69 15.22 4.71 14.55ZM19.29 14.55C20.31 15.22 21 16.06 21 17.07V19H24V17.5C24 15.76 21.22 14.78 19.29 14.55ZM12 16C13.53 16 15.24 16.5 16.23 17H7.77C8.76 16.5 10.47 16 12 16Z"
                          fill="currentColor"></path>
                      </svg></div>
                    <div class="uui-space-small-5"></div>
                    <h3 class="uui-heading-xxsmall-6 light"><strong>Red de Ayuda y Asistencia</strong></h3>
                    <div class="uui-space-xxsmall-6"></div>
                    <div class="uui-text-size-medium-6 light">Crea una <strong>red de contactos de emergencia</strong>
                      para comunicarte de inmediato con tu equipo médico o familiares a través de videollamada.</div>
                  </div>
                  <div id="w-node-dc2787c6-fa83-b889-37ac-cf656b6b4656-cc03f7ee" class="uui-layout79_item">
                    <div class="uui-icon-featured-outline-large-5"><svg width="100%" height="100%" viewbox="0 0 24 24"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M17 9.00005H16.5H17ZM7 15.0001H10C10.08 14.3201 10.23 13.6401 10.5 13.0001H7V15.0001ZM11.9 10.9001C13.17 9.64005 14.84 9.00005 16.5 9.00005H7V11.0001H11.82C11.85 10.9701 11.87 10.9401 11.9 10.9001ZM11.9 20.1001C12.26 20.4501 12.65 20.7401 13.06 21.0001H5C3.9 21.0001 3 20.1101 3 19.0001V5.00005C3 3.90005 3.9 3.00005 5 3.00005H9.18C9.5 2.16005 10.14 1.50005 11 1.20005C12.53 0.640055 14.25 1.44005 14.82 3.00005H19C20.11 3.00005 21 3.90005 21 5.00005V10.8201C20.4 10.2401 19.72 9.80005 19 9.50005V5.00005H17V7.00005H7V5.00005H5V19.0001H11.03C11.27 19.3901 11.57 19.7601 11.9 20.1001ZM11 4.00005C11 4.55005 11.45 5.00005 12 5.00005C12.55 5.00005 13 4.55005 13 4.00005C13 3.45005 12.55 3.00005 12 3.00005C11.45 3.00005 11 3.45005 11 4.00005ZM23.39 21.0001L22 22.3901L18.88 19.3201C18.19 19.7501 17.37 20.0001 16.5 20.0001C14 20.0001 12 18.0001 12 15.5001C12 13.0001 14 11.0001 16.5 11.0001C19 11.0001 21 13.0001 21 15.5001C21 16.3801 20.75 17.2101 20.31 17.9001L23.39 21.0001ZM19 15.5001C19 14.1201 17.88 13.0001 16.5 13.0001C15.12 13.0001 14 14.1201 14 15.5001C14 16.8801 15.12 18.0001 16.5 18.0001C17.88 18.0001 19 16.8801 19 15.5001Z"
                          fill="currentColor"></path>
                      </svg></div>
                    <div class="uui-space-small-5"></div>
                    <h3 class="uui-heading-xxsmall-6 light"><strong>Historial de Mediciones</strong></h3>
                    <div class="uui-space-xxsmall-6"></div>
                    <div class="uui-text-size-medium-6 light">Consulta en cualquier momento tu <strong>historial de
                        salud</strong> desde la App Móvil o el Panel Web de iDUAM, facilitando un mejor seguimiento
                      médico.</div>
                  </div>
                  <div id="w-node-_21553ef6-547b-ef23-38d2-12e16c515a8b-cc03f7ee" class="uui-layout79_item tab">
                    <h3 class="uui-heading-xxsmall-6"><strong>¿Necesitas más información?</strong></h3>
                    <div class="uui-space-xxsmall-6"></div>
                    <div class="uui-text-size-medium-6">Contáctanos y descubre cómo la iDUAM G-50 puede mejorar tu
                      bienestar</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="uui-section_pricing01">
        <div class="uui-page-padding-8">
          <div class="uui-container-large-7">
            <div class="uui-padding-vertical-xhuge-12">
              <div class="uui-max-width-large-6">
                <div class="uui-heading-subheading-6">El mejor servicio</div>
                <h2 class="uui-heading-medium-7"><strong>Beneficios Adicionales</strong></h2>
                <div class="uui-space-xsmall-7"></div>
                <div class="uui-text-size-large-7">Al comprar obtienes acceso exclusivo a una serie de beneficios
                  diseñados para mejorar tu experiencia y bienestar.</div>
              </div>
              <div class="w-layout-grid uui-pricing01_component">
                <div class="uui-pricing01_content">
                  <div class="w-layout-grid uui-pricing01_content-list">
                    <div id="w-node-_54a5198f-18c7-7ec2-13bf-12515d2df2f9-cc03f7ee" class="uui-pricing01_content-item">
                      <div class="uui-pricing01_item-icon-wrapper">
                        <div class="uui-icon-featured-outline-large-6">
                          <div class="uui-icon-1x1-xsmall-7 w-embed"><svg width="24" height="24" viewbox="0 0 24 24"
                              fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M6.09436 11.2288C6.03221 10.8282 5.99996 10.4179 5.99996 10C5.99996 5.58172 9.60525 2 14.0526 2C18.4999 2 22.1052 5.58172 22.1052 10C22.1052 10.9981 21.9213 11.9535 21.5852 12.8345C21.5154 13.0175 21.4804 13.109 21.4646 13.1804C21.4489 13.2512 21.4428 13.301 21.4411 13.3735C21.4394 13.4466 21.4493 13.5272 21.4692 13.6883L21.8717 16.9585C21.9153 17.3125 21.9371 17.4895 21.8782 17.6182C21.8266 17.731 21.735 17.8205 21.6211 17.8695C21.4911 17.9254 21.3146 17.8995 20.9617 17.8478L17.7765 17.3809C17.6101 17.3565 17.527 17.3443 17.4512 17.3448C17.3763 17.3452 17.3245 17.3507 17.2511 17.3661C17.177 17.3817 17.0823 17.4172 16.893 17.4881C16.0097 17.819 15.0524 18 14.0526 18C13.6344 18 13.2237 17.9683 12.8227 17.9073M7.63158 22C10.5965 22 13 19.5376 13 16.5C13 13.4624 10.5965 11 7.63158 11C4.66668 11 2.26316 13.4624 2.26316 16.5C2.26316 17.1106 2.36028 17.6979 2.53955 18.2467C2.61533 18.4787 2.65322 18.5947 2.66566 18.6739C2.67864 18.7567 2.68091 18.8031 2.67608 18.8867C2.67145 18.9668 2.65141 19.0573 2.61134 19.2383L2 22L4.9948 21.591C5.15827 21.5687 5.24 21.5575 5.31137 21.558C5.38652 21.5585 5.42641 21.5626 5.50011 21.5773C5.5701 21.5912 5.67416 21.6279 5.88227 21.7014C6.43059 21.8949 7.01911 22 7.63158 22Z"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                              </path>
                            </svg></div>
                        </div>
                      </div>
                      <div class="uui-pricing01_item-text-wrapper">
                        <h5 class="uui-heading-xxsmall-7"> Envío Gratuito </h5>
                        <div class="uui-space-xxsmall-7"></div>
                        <div class="uui-text-size-medium-7">Adquiere tu iDUAM G-50 hoy y recíbela en la puerta de tu
                          hogar sin costo adicional.</div>
                      </div>
                    </div>
                    <div id="w-node-_54a5198f-18c7-7ec2-13bf-12515d2df303-cc03f7ee" class="uui-pricing01_content-item">
                      <div class="uui-pricing01_item-icon-wrapper">
                        <div class="uui-icon-featured-outline-large-6">
                          <div class="uui-icon-1x1-xsmall-7 w-embed"><svg width="24" height="24" viewbox="0 0 24 24"
                              fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M13.0001 2L4.09356 12.6879C3.74475 13.1064 3.57035 13.3157 3.56768 13.4925C3.56537 13.6461 3.63384 13.7923 3.75336 13.8889C3.89085 14 4.16328 14 4.70814 14H12.0001L11.0001 22L19.9067 11.3121C20.2555 10.8936 20.4299 10.6843 20.4325 10.5075C20.4348 10.3539 20.3664 10.2077 20.2468 10.1111C20.1094 10 19.8369 10 19.2921 10H12.0001L13.0001 2Z"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                              </path>
                            </svg></div>
                        </div>
                      </div>
                      <div class="uui-pricing01_item-text-wrapper">
                        <h5 class="uui-heading-xxsmall-7">Garantía de Satisfacción</h5>
                        <div class="uui-space-xxsmall-7"></div>
                        <div class="uui-text-size-medium-7">Compra con total confianza, respaldado por nuestra garantía de
                          satisfacción.</div>
                      </div>
                    </div>
                    <div id="w-node-_54a5198f-18c7-7ec2-13bf-12515d2df30d-cc03f7ee" class="uui-pricing01_content-item">
                      <div class="uui-pricing01_item-icon-wrapper">
                        <div class="uui-icon-featured-outline-large-6">
                          <div class="uui-icon-1x1-xsmall-7 w-embed"><svg width="24" height="24" viewbox="0 0 24 24"
                              fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M11 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21H16.2C17.8802 21 18.7202 21 19.362 20.673C19.9265 20.3854 20.3854 19.9265 20.673 19.362C21 18.7202 21 17.8802 21 16.2V13M12 8H16V12M15.5 3.5V2M19.4393 4.56066L20.5 3.5M20.5103 8.5H22.0103M3 13.3471C3.65194 13.4478 4.31987 13.5 5 13.5C9.38636 13.5 13.2653 11.3276 15.6197 8"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                              </path>
                            </svg></div>
                        </div>
                      </div>
                      <div class="uui-pricing01_item-text-wrapper">
                        <h5 class="uui-heading-xxsmall-7">Soporte Técnico Especializado</h5>
                        <div class="uui-space-xxsmall-7"></div>
                        <div class="uui-text-size-medium-7">Está disponible para asistirte cuando lo necesites a través de
                          múltiples canales de atención.</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="uui-pricing01_plan">
                  <div class="uui-pricing01_plan-content">
                    <div class="uui-pricing01_content-top">
                      <div class="uui-pricing01_top-left">
                        <div class="uui-pricing01_plan-name-wrapper">
                          <h2 class="uui-heading-xsmall-4">iDUAM G-50</h2>
                        </div>
                        <div class="uui-text-size-medium-7">La mejor tecnología para tu salud</div>
                      </div>
                      <div class="uui-pricing01_top-right">
                        <div class="uui-pricing01_top-right-price"><span class="uui-pricing01_top-right-text">Ahora por
                            solo </span></div>
                        <div class="uui-pricing01_top-right-price"><strong>$89.990</strong></div>
                        <div class="uui-pricing01_top-right-price"
                          style="color: #98a2b3; font-size: 1.3rem; font-weight: 300; text-decoration: line-through;">
                          Antes $99.990</div>
                      </div>
                    </div>
                    <div class="uui-pricing01_divider"></div>
                    <div class="uui-pricing01_card-heading">Beneficios</div>
                    <div class="uui-text-size-medium-7">Con la compra adquiere los siguientes beneficios: </div>
                    <div class="uui-pricing01_feature-list">
                      <div id="w-node-_54a5198f-18c7-7ec2-13bf-12515d2df331-cc03f7ee" class="uui-pricing01_feature">
                        <div class="uui-pricing01_icon-wrapper">
                          <div class="uui-icon-1x1-xsmall-7 w-embed"><svg width="24" height="24" viewbox="0 0 24 24"
                              fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
                                fill="#D1FADF"></path>
                              <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z"
                                fill="#12B76A"></path>
                            </svg></div>
                        </div>
                        <div class="uui-text-size-medium-7"><strong>Conectividad total:</strong> Aplicación móvil y
                          sincronización con la smartband </div>
                      </div>
                      <div id="w-node-_54a5198f-18c7-7ec2-13bf-12515d2df336-cc03f7ee" class="uui-pricing01_feature">
                        <div class="uui-pricing01_icon-wrapper">
                          <div class="uui-icon-1x1-xsmall-7 w-embed"><svg width="24" height="24" viewbox="0 0 24 24"
                              fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
                                fill="#D1FADF"></path>
                              <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z"
                                fill="#12B76A"></path>
                            </svg></div>
                        </div>
                        <div class="uui-text-size-medium-7"><strong>Mediciones avanzadas:</strong> Registra manualmente o
                          de forma automática datos tipo Holter</div>
                      </div>
                      <div id="w-node-_54a5198f-18c7-7ec2-13bf-12515d2df33b-cc03f7ee" class="uui-pricing01_feature">
                        <div class="uui-pricing01_icon-wrapper">
                          <div class="uui-icon-1x1-xsmall-7 w-embed"><svg width="24" height="24" viewbox="0 0 24 24"
                              fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
                                fill="#D1FADF"></path>
                              <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z"
                                fill="#12B76A"></path>
                            </svg></div>
                        </div>
                        <div class="uui-text-size-medium-7"><strong>Electrocardiograma con IA:</strong> Realiza ECG con
                          interpretación inteligente en tiempo real</div>
                      </div>
                      <div id="w-node-_54a5198f-18c7-7ec2-13bf-12515d2df340-cc03f7ee" class="uui-pricing01_feature">
                        <div class="uui-pricing01_icon-wrapper">
                          <div class="uui-icon-1x1-xsmall-7 w-embed"><svg width="24" height="24" viewbox="0 0 24 24"
                              fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
                                fill="#D1FADF"></path>
                              <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z"
                                fill="#12B76A"></path>
                            </svg></div>
                        </div>
                        <div class="uui-text-size-medium-7"><strong>Historial de salud:</strong> Accede a todas tus
                          mediciones desde la App o el Portal Web</div>
                      </div>
                      <div id="w-node-_54a5198f-18c7-7ec2-13bf-12515d2df345-cc03f7ee" class="uui-pricing01_feature">
                        <div class="uui-pricing01_icon-wrapper">
                          <div class="uui-icon-1x1-xsmall-7 w-embed"><svg width="24" height="24" viewbox="0 0 24 24"
                              fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
                                fill="#D1FADF"></path>
                              <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z"
                                fill="#12B76A"></path>
                            </svg></div>
                        </div>
                        <div class="uui-text-size-medium-7"><strong>Red de Ayuda:</strong> Comparte alertas de
                          emergencia con hasta 5 contactos de confianza </div>
                      </div>
                    </div>
                    <div class="uui-pricing01_divider"></div>
                    <a href="https://ventasiduam.web.app/finalizar-compra?product_id=3" target="_blank"
                      class="uui-button w-inline-block">
                      <div>Comprar</div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section data-w-id="57614bf4-2915-585b-8315-07954773cf0c" class="uui-section_testimonial16">
        <div class="uui-padding-vertical-xhuge-10">
          <div class="uui-text-align-center-4">
            <div class="uui-max-width-large-5 align-center-3">
              <h2 class="uui-heading-medium-6"><strong>¿Ya tienes tu iDUAM G-50?</strong></h2>
              <div class="uui-space-xsmall-6"></div>
              <div class="uui-text-size-large-6">¡Comparte tu experiencia con nosotros y ayuda a más personas a descubrir
                sus beneficios!</div>
            </div>
          </div>
          <div class="mt-5">
            <VueSlickCarousel :dots="true" autoplay infinite :slidesToShow="3" :responsive="responsive_carousel">
  
              <div>
                <div style="" class="uui-testimonial16_content">
                  <div class="uui-testimonial16_rating-wrapper">
                    <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_28_8746)">
                          <path
                            d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                            fill="currentColor"></path>
                        </g>
                      </svg></div>
                    <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_28_8746)">
                          <path
                            d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                            fill="currentColor"></path>
                        </g>
                      </svg></div>
                    <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_28_8746)">
                          <path
                            d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                            fill="currentColor"></path>
                        </g>
                      </svg></div>
                    <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_28_8746)">
                          <path
                            d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                            fill="currentColor"></path>
                        </g>
                      </svg></div>
                    <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_28_8746)">
                          <path
                            d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                            fill="currentColor"></path>
                        </g>
                      </svg></div>
                  </div>
                  <div class="uui-heading-xxsmall-6 text-weight-medium"><em>“Desde que uso la iDUAM G-50, monitoreo mi
                      presión arterial y mi ritmo cardíaco todos los días. Antes tenía que ir constantemente al médico,
                      pero
                      ahora tengo un control más preciso desde mi casa. ¡Me ha dado tranquilidad y seguridad!”</em></div>
                  <div class="uui-testimonial16_client">
                    <div class="uui-testimonial16_client-info">
                      <div class="uui-testimonial16_client-heading">María González, 65 años</div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div style="" class="uui-testimonial16_content">
                  <div class="uui-testimonial16_rating-wrapper">
                    <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_28_8746)">
                          <path
                            d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                            fill="currentColor"></path>
                        </g>
                      </svg></div>
                    <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_28_8746)">
                          <path
                            d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                            fill="currentColor"></path>
                        </g>
                      </svg></div>
                    <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_28_8746)">
                          <path
                            d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                            fill="currentColor"></path>
                        </g>
                      </svg></div>
                    <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_28_8746)">
                          <path
                            d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                            fill="currentColor"></path>
                        </g>
                      </svg></div>
                    <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_28_8746)">
                          <path
                            d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                            fill="currentColor"></path>
                        </g>
                      </svg></div>
                  </div>
                  <div class="uui-heading-xxsmall-6 text-weight-medium"><em>“Trabajo largas horas y no siempre tengo
                      tiempo
                      para chequear mi salud. Con la iDUAM G-50, puedo ver mis mediciones en la app en segundos y recibir
                      alertas cuando algo no está bien. Es como tener un médico en mi muñeca.”</em></div>
                  <div class="uui-testimonial16_client">
                    <div class="uui-testimonial16_client-info">
                      <div class="uui-testimonial16_client-heading">Fernando Castillo, 32 años</div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div style="" class="uui-testimonial16_content">
                  <div class="uui-testimonial16_rating-wrapper">
                    <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_28_8746)">
                          <path
                            d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                            fill="currentColor"></path>
                        </g>
                      </svg></div>
                    <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_28_8746)">
                          <path
                            d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                            fill="currentColor"></path>
                        </g>
                      </svg></div>
                    <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_28_8746)">
                          <path
                            d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                            fill="currentColor"></path>
                        </g>
                      </svg></div>
                    <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_28_8746)">
                          <path
                            d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                            fill="currentColor"></path>
                        </g>
                      </svg></div>
                    <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_28_8746)">
                          <path
                            d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                            fill="currentColor"></path>
                        </g>
                      </svg></div>
                  </div>
                  <div class="uui-heading-xxsmall-6 text-weight-medium"><em>“Mi papá es hipertenso y a veces olvidaba
                      medirse la presión. Con la iDUAM G-50, recibe recordatorios y nosotros podemos ver sus mediciones
                      en
                      tiempo real. Nos ha ayudado a reaccionar rápido ante cualquier cambio en su salud.”</em></div>
                  <div class="uui-testimonial16_client">
                    <div class="uui-testimonial16_client-info">
                      <div class="uui-testimonial16_client-heading"> <strong>Andrea López, hija de usuario</strong></div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div style="" class="uui-testimonial16_content">
                  <div class="uui-testimonial16_rating-wrapper">
                    <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_28_8746)">
                          <path
                            d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                            fill="currentColor"></path>
                        </g>
                      </svg></div>
                    <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_28_8746)">
                          <path
                            d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                            fill="currentColor"></path>
                        </g>
                      </svg></div>
                    <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_28_8746)">
                          <path
                            d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                            fill="currentColor"></path>
                        </g>
                      </svg></div>
                    <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_28_8746)">
                          <path
                            d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                            fill="currentColor"></path>
                        </g>
                      </svg></div>
                    <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_28_8746)">
                          <path
                            d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                            fill="currentColor"></path>
                        </g>
                      </svg></div>
                  </div>
                  <div class="uui-heading-xxsmall-6 text-weight-medium"><em>“Practico ciclismo y me encanta analizar mi
                      rendimiento. La iDUAM G-50 me permite monitorear mi frecuencia cardíaca y niveles de oxígeno en
                      sangre mientras entreno. ¡Es perfecta para mejorar mi rendimiento y cuidar mi salud!”</em></div>
                  <div class="uui-testimonial16_client">
                    <div class="uui-testimonial16_client-info">
                      <div class="uui-testimonial16_client-heading">Carlos Ramírez, 45 años</div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div style="" class="uui-testimonial16_content">
                  <div class="uui-testimonial16_rating-wrapper">
                    <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_28_8746)">
                          <path
                            d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                            fill="currentColor"></path>
                        </g>
                      </svg></div>
                    <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_28_8746)">
                          <path
                            d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                            fill="currentColor"></path>
                        </g>
                      </svg></div>
                    <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_28_8746)">
                          <path
                            d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                            fill="currentColor"></path>
                        </g>
                      </svg></div>
                    <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_28_8746)">
                          <path
                            d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                            fill="currentColor"></path>
                        </g>
                      </svg></div>
                    <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_28_8746)">
                          <path
                            d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                            fill="currentColor"></path>
                        </g>
                      </svg></div>
                  </div>
                  <div class="uui-heading-xxsmall-6 text-weight-medium"><em>“Mi madre es diabética e hipertensa, y antes
                      teníamos que medir su presión con equipos tradicionales varias veces al día. Ahora, con la iDUAM
                      G-50, todo se registra automáticamente en la app y podemos ver su estado en cualquier momento. Nos
                      ha
                      dado mucha más tranquilidad.”</em></div>
                  <div class="uui-testimonial16_client">
                    <div class="uui-testimonial16_client-info">
                      <div class="uui-testimonial16_client-heading"><strong>Daniela Fuentes, 40 años</strong></div>
                    </div>
                  </div>
                </div>
              </div>
  
              <!--             <div>
              <div style="" class="uui-testimonial16_content">
                <div class="uui-testimonial16_rating-wrapper">
                  <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                      fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_28_8746)">
                        <path
                          d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                          fill="currentColor"></path>
                      </g>
                    </svg></div>
                  <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                      fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_28_8746)">
                        <path
                          d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                          fill="currentColor"></path>
                      </g>
                    </svg></div>
                  <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                      fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_28_8746)">
                        <path
                          d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                          fill="currentColor"></path>
                      </g>
                    </svg></div>
                  <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                      fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_28_8746)">
                        <path
                          d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                          fill="currentColor"></path>
                      </g>
                    </svg></div>
                  <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                      fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_28_8746)">
                        <path
                          d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                          fill="currentColor"></path>
                      </g>
                    </svg></div>
                </div>
                <div class="uui-heading-xxsmall-6 text-weight-medium"><em>“Regalé la iDUAM G-50 a mi abuelo y ha sido
                    increíble. Antes olvidaba medir su presión, pero ahora lo hace de forma automática y su médico puede
                    revisar su historial. Además, si algo anda mal, la red de seguridad nos avisa de inmediato.”</em>
                </div>
                <div class="uui-testimonial16_client">
                  <div class="uui-testimonial16_client-info">
                    <div class="uui-testimonial16_client-heading"><strong>Alejandro Pérez, 35 años</strong></div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div style="" class="uui-testimonial16_content">
                <div class="uui-testimonial16_rating-wrapper">
                  <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                      fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_28_8746)">
                        <path
                          d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                          fill="currentColor"></path>
                      </g>
                    </svg></div>
                  <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                      fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_28_8746)">
                        <path
                          d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                          fill="currentColor"></path>
                      </g>
                    </svg></div>
                  <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                      fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_28_8746)">
                        <path
                          d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                          fill="currentColor"></path>
                      </g>
                    </svg></div>
                  <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                      fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_28_8746)">
                        <path
                          d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                          fill="currentColor"></path>
                      </g>
                    </svg></div>
                  <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                      fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_28_8746)">
                        <path
                          d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                          fill="currentColor"></path>
                      </g>
                    </svg></div>
                </div>
                <div class="uui-heading-xxsmall-6 text-weight-medium"><em>“Trabajo en un hospital y siempre recomiendo la
                    iDUAM G-50 a mis pacientes, especialmente a quienes necesitan monitoreo constante. Es precisa, fácil
                    de usar y su integración con la app la hace ideal para la telemedicina.”</em></div>
                <div class="uui-testimonial16_client">
                  <div class="uui-testimonial16_client-info">
                    <div class="uui-testimonial16_client-heading"><strong>Dra. Verónica Sánchez, Cardióloga</strong></div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div style="" class="uui-testimonial16_content">
                <div class="uui-testimonial16_rating-wrapper">
                  <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                      fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_28_8746)">
                        <path
                          d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                          fill="currentColor"></path>
                      </g>
                    </svg></div>
                  <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                      fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_28_8746)">
                        <path
                          d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                          fill="currentColor"></path>
                      </g>
                    </svg></div>
                  <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                      fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_28_8746)">
                        <path
                          d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                          fill="currentColor"></path>
                      </g>
                    </svg></div>
                  <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                      fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_28_8746)">
                        <path
                          d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                          fill="currentColor"></path>
                      </g>
                    </svg></div>
                  <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                      fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_28_8746)">
                        <path
                          d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                          fill="currentColor"></path>
                      </g>
                    </svg></div>
                </div>
                <div class="uui-heading-xxsmall-6 text-weight-medium"><em>“Nunca había usado una smartband, pero la iDUAM
                    G-50 es fácil de configurar y usar. Me gusta que la batería dura varios días y que las mediciones se
                    sincronizan automáticamente con la app. Me ha ayudado a cuidar mi salud sin esfuerzo.”</em></div>
                <div class="uui-testimonial16_client">
                  <div class="uui-testimonial16_client-info">
                    <div class="uui-testimonial16_client-heading"><strong>Rosa Aguilar, 50 años</strong></div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div style="" class="uui-testimonial16_content">
                <div class="uui-testimonial16_rating-wrapper">
                  <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                      fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_28_8746)">
                        <path
                          d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                          fill="currentColor"></path>
                      </g>
                    </svg></div>
                  <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                      fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_28_8746)">
                        <path
                          d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                          fill="currentColor"></path>
                      </g>
                    </svg></div>
                  <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                      fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_28_8746)">
                        <path
                          d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                          fill="currentColor"></path>
                      </g>
                    </svg></div>
                  <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                      fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_28_8746)">
                        <path
                          d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                          fill="currentColor"></path>
                      </g>
                    </svg></div>
                  <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                      fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_28_8746)">
                        <path
                          d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                          fill="currentColor"></path>
                      </g>
                    </svg></div>
                </div>
                <div class="uui-heading-xxsmall-6 text-weight-medium"><em>“Tengo antecedentes de problemas cardíacos y
                    necesitaba una forma de monitorear mi ritmo cardíaco a diario. La iDUAM G-50 no solo me da mediciones
                    precisas, sino que también me avisa si algo está fuera de lo normal. Es una gran herramienta para la
                    prevención.”</em></div>
                <div class="uui-testimonial16_client">
                  <div class="uui-testimonial16_client-info">
                    <div class="uui-testimonial16_client-heading"><strong>Javier Méndez, 55 años</strong></div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div style="" class="uui-testimonial16_content">
                <div class="uui-testimonial16_rating-wrapper">
                  <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                      fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_28_8746)">
                        <path
                          d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                          fill="currentColor"></path>
                      </g>
                    </svg></div>
                  <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                      fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_28_8746)">
                        <path
                          d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                          fill="currentColor"></path>
                      </g>
                    </svg></div>
                  <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                      fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_28_8746)">
                        <path
                          d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                          fill="currentColor"></path>
                      </g>
                    </svg></div>
                  <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                      fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_28_8746)">
                        <path
                          d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                          fill="currentColor"></path>
                      </g>
                    </svg></div>
                  <div class="uui-testimonial16_rating-icon w-embed"><svg width="20" height="20" viewbox="0 0 20 20"
                      fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_28_8746)">
                        <path
                          d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                          fill="currentColor"></path>
                      </g>
                    </svg></div>
                </div>
                <div class="uui-heading-xxsmall-6 text-weight-medium"><em>“Un día sentí un dolor en el pecho y mi iDUAM
                    G-50 detectó una alteración en mi ritmo cardíaco. Gracias a la función de alerta, mi familia recibió
                    la notificación y me ayudaron a buscar atención médica de inmediato. ¡Realmente puede salvar
                    vidas!”</em></div>
                <div class="uui-testimonial16_client">
                  <div class="uui-testimonial16_client-info">
                    <div class="uui-testimonial16_client-heading"> <strong>Patricia Herrera, 58 años</strong></div>
                  </div>
                </div>
              </div>
            </div>
   -->
            </VueSlickCarousel>
          </div>
        </div>
      </section>
      <section class="uui-section_faq04-2">
        <div class="uui-page-padding-9">
          <div class="uui-container-large-8">
            <div class="uui-padding-vertical-xhuge-13">
              <div class="uui-text-align-center-5">
                <div class="uui-max-width-large-7 align-center-4">
                  <!--                 <div class="uui-heading-subheading-7">Support</div> -->
                  <h2 class="uui-heading-medium-8"><strong>Preguntas Frecuentes </strong></h2>
                  <div class="uui-space-xsmall-8"></div>
                  <div class="uui-text-size-large-8">iDUAM G-50</div>
                </div>
              </div>
              <v-row class="mt-5">
                <v-col cols="12" md="6">
                  <v-expansion-panels>
                    <v-expansion-panel v-for="(item, i) in items1" :key="i">
                      <v-expansion-panel-header>
                        <p class="uui-faq04_heading-2"> {{ item.question }} </p>
                        <template v-slot:actions>
                          <v-icon color="gray">
                            mdi-plus-circle-outline
                          </v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        {{ item.answer }}
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
                <v-col cols="12" md="6">
                  <v-expansion-panels>
                    <v-expansion-panel v-for="(item, i) in items2" :key="i">
                      <v-expansion-panel-header>
                        <p class="uui-faq04_heading-2"> {{ item.question }} </p>
                        <template v-slot:actions>
                          <v-icon color="gray">
                            mdi-plus-circle-outline
                          </v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        {{ item.answer }}
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
              <!--             <div class="uui-faq04_component-2">
                <div class="w-layout-grid uui-faq04_layout-2">
                  <div class="uui-faq04_list-2 first-col">
                    <div class="uui-faq04_accordion-2">
                      <div data-w-id="b039f40f-dbc9-f3da-055e-69438bae8980" class="uui-faq04_question-2">
                        <div class="uui-faq04_heading-2"> ¿Qué funciones ofrece la iDUAM G-50?</div>
                        <div class="uui-faq04_icon-wrapper-2">
                          <div class="accordion-icon_component-2">
                            <div class="accordion-icon_horizontal-line-2"></div>
                            <div class="accordion-icon_vertical-line-2"></div>
                          </div>
                        </div>
                      </div>
                      <div style="height:0px" class="uui-faq04_answer-2">
                        <div class="uui-text-size-medium-8">La smartband iDUAM G-50 mide ritmo cardíaco, presión
                          arterial, saturación de oxígeno (SpO2), temperatura corporal y electrocardiograma (ECG). Además,
                          permite el monitoreo en tiempo real desde la App iDUAM.</div>
                        <div class="uui-space-medium-5"></div>
                      </div>
                    </div>
                    <div class="uui-faq04_accordion-2">
                      <div data-w-id="b039f40f-dbc9-f3da-055e-69438bae898c" class="uui-faq04_question-2">
                        <div class="uui-faq04_heading-2">¿Es compatible con cualquier teléfono móvil?</div>
                        <div class="uui-faq04_icon-wrapper-2">
                          <div class="accordion-icon_component-2">
                            <div class="accordion-icon_horizontal-line-2"></div>
                            <div class="accordion-icon_vertical-line-2"></div>
                          </div>
                        </div>
                      </div>
                      <div style="height:0px" class="uui-faq04_answer-2">
                        <div class="uui-text-size-medium-8">Sí, la iDUAM G-50 es compatible con dispositivos Android
                          (versión 7.0 o superior) y iOS (versión 12.0 o superior).</div>
                        <div class="uui-space-medium-5"></div>
                      </div>
                    </div>
                    <div class="uui-faq04_accordion-2">
                      <div data-w-id="b039f40f-dbc9-f3da-055e-69438bae8998" class="uui-faq04_question-2">
                        <div class="uui-faq04_heading-2">¿Cómo se conecta con la App de iDUAM?</div>
                        <div class="uui-faq04_icon-wrapper-2">
                          <div class="accordion-icon_component-2">
                            <div class="accordion-icon_horizontal-line-2"></div>
                            <div class="accordion-icon_vertical-line-2"></div>
                          </div>
                        </div>
                      </div>
                      <div style="height:0px" class="uui-faq04_answer-2">
                        <div class="uui-text-size-medium-8">Solo debes descargar la App iDUAM desde Google Play o App
                          Store, registrarte y vincular la pulsera a través de Bluetooth siguiendo las instrucciones en
                          pantalla.</div>
                        <div class="uui-space-medium-5"></div>
                      </div>
                    </div>
                    <div class="uui-faq04_accordion-2">
                      <div data-w-id="b039f40f-dbc9-f3da-055e-69438bae89a4" class="uui-faq04_question-2">
                        <div class="uui-faq04_heading-2">¿Cómo se realiza un electrocardiograma (ECG)?</div>
                        <div class="uui-faq04_icon-wrapper-2">
                          <div class="accordion-icon_component-2">
                            <div class="accordion-icon_horizontal-line-2"></div>
                            <div class="accordion-icon_vertical-line-2"></div>
                          </div>
                        </div>
                      </div>
                      <div style="height:0px" class="uui-faq04_answer-2">
                        <div class="uui-text-size-medium-8">Desde la App iDUAM, selecciona la opción de
                          <strong>ECG</strong>, coloca el dedo en el sensor de la pulsera y espera la medición. Los
                          resultados se almacenarán automáticamente en tu historial.<strong>‍</strong>
                        </div>
                        <div class="uui-space-medium-5"></div>
                      </div>
                    </div>
                    <div class="uui-faq04_accordion-2 last-item">
                      <div data-w-id="b039f40f-dbc9-f3da-055e-69438bae89b0" class="uui-faq04_question-2">
                        <div class="uui-faq04_heading-2">¿Qué hago si tengo problemas de conexión con la pulsera?</div>
                        <div class="uui-faq04_icon-wrapper-2">
                          <div class="accordion-icon_component-2">
                            <div class="accordion-icon_horizontal-line-2"></div>
                            <div class="accordion-icon_vertical-line-2"></div>
                          </div>
                        </div>
                      </div>
                      <div style="height:0px" class="uui-faq04_answer-2">
                        <div class="uui-text-size-medium-8">1. Asegúrate de que <strong>Bluetooth está activado</strong>
                          en tu teléfono. 2. Reinicia la pulsera y el teléfono. 3. Verifica que la pulsera tiene
                          suficiente <strong>batería</strong>. 4. Si el problema persiste, desinstala y vuelve a instalar
                          la App iDUAM.</div>
                        <div class="uui-space-medium-5"></div>
                      </div>
                    </div>
                  </div>
                  <div class="uui-faq04_list-2">
                    <div class="uui-faq04_accordion-2">
                      <div data-w-id="b039f40f-dbc9-f3da-055e-69438bae89bd" class="uui-faq04_question-2">
                        <div class="uui-faq04_heading-2">¿Cuánta batería tiene y cuánto dura?</div>
                        <div class="uui-faq04_icon-wrapper-2">
                          <div class="accordion-icon_component-2">
                            <div class="accordion-icon_horizontal-line-2"></div>
                            <div class="accordion-icon_vertical-line-2"></div>
                          </div>
                        </div>
                      </div>
                      <div style="height:0px" class="uui-faq04_answer-2">
                        <div class="uui-text-size-medium-8">La batería de la iDUAM G-50 tiene una duración de hasta 5
                          días con uso continuo y puede recargarse completamente en aproximadamente 2 horas.</div>
                        <div class="uui-space-medium-5"></div>
                      </div>
                    </div>
                    <div class="uui-faq04_accordion-2">
                      <div data-w-id="b039f40f-dbc9-f3da-055e-69438bae89c9" class="uui-faq04_question-2">
                        <div class="uui-faq04_heading-2">¿Es resistente al agua?</div>
                        <div class="uui-faq04_icon-wrapper-2">
                          <div class="accordion-icon_component-2">
                            <div class="accordion-icon_horizontal-line-2"></div>
                            <div class="accordion-icon_vertical-line-2"></div>
                          </div>
                        </div>
                      </div>
                      <div style="height:0px" class="uui-faq04_answer-2">
                        <div class="uui-text-size-medium-8">Sí, cuenta con certificación IP67, lo que la hace resistente a
                          salpicaduras, sudor y polvo, pero no es apta para natación o inmersiones prolongadas.</div>
                        <div class="uui-space-medium-5"></div>
                      </div>
                    </div>
                    <div class="uui-faq04_accordion-2">
                      <div data-w-id="b039f40f-dbc9-f3da-055e-69438bae89d5" class="uui-faq04_question-2">
                        <div class="uui-faq04_heading-2">¿Necesito tener la App abierta para que funcione?</div>
                        <div class="uui-faq04_icon-wrapper-2">
                          <div class="accordion-icon_component-2">
                            <div class="accordion-icon_horizontal-line-2"></div>
                            <div class="accordion-icon_vertical-line-2"></div>
                          </div>
                        </div>
                      </div>
                      <div style="height:0px" class="uui-faq04_answer-2">
                        <div class="uui-text-size-medium-8">No es necesario. La pulsera recopila datos de forma autónoma y
                          los sincroniza con la App cuando está conectada.</div>
                        <div class="uui-space-medium-5"></div>
                      </div>
                    </div>
                    <div class="uui-faq04_accordion-2">
                      <div data-w-id="b039f40f-dbc9-f3da-055e-69438bae89e1" class="uui-faq04_question-2">
                        <div class="uui-faq04_heading-2">¿Puedo compartir mis mediciones con mi médico o familiares?</div>
                        <div class="uui-faq04_icon-wrapper-2">
                          <div class="accordion-icon_component-2">
                            <div class="accordion-icon_horizontal-line-2"></div>
                            <div class="accordion-icon_vertical-line-2"></div>
                          </div>
                        </div>
                      </div>
                      <div style="height:0px" class="uui-faq04_answer-2">
                        <div class="uui-text-size-medium-8">Sí, puedes compartir tu historial de salud desde la App iDUAM
                          o dar acceso a tu Red de Seguridad, para que puedan recibir alertas y reportes de tu estado de
                          salud</div>
                        <div class="uui-space-medium-5"></div>
                      </div>
                    </div>
                    <div class="uui-faq04_accordion-2 last-item">
                      <div data-w-id="b039f40f-dbc9-f3da-055e-69438bae89ed" class="uui-faq04_question-2">
                        <div class="uui-faq04_heading-2">¿Dónde puedo comprar la iDUAM G-50?</div>
                        <div class="uui-faq04_icon-wrapper-2">
                          <div class="accordion-icon_component-2">
                            <div class="accordion-icon_horizontal-line-2"></div>
                            <div class="accordion-icon_vertical-line-2"></div>
                          </div>
                        </div>
                      </div>
                      <div style="height:0px" class="uui-faq04_answer-2">
                        <div class="uui-text-size-medium-8">Puedes adquirirla directamente en nuestra tienda en línea o a
                          través de distribuidores autorizados. Pago seguro con Webpay y tarjetas de crédito/débito.</div>
                        <div class="uui-space-medium-5"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="uui-faq04_cta-2">
                  <div class="uui-text-align-center-5">
                    <h4 class="uui-heading-xxsmall-8">¿Tienes más dudas? </h4>
                    <div class="uui-space-xxsmall-8"></div>
                    <div class="uui-text-size-large-8">Contáctanos y te ayudaremos. </div>
                    <div class="uui-space-small-7"></div>
                    <div class="uui-button-row-8 button-row-center is-reverse-mobile-landscape">
                      <a href="#" class="uui-button-secondary-gray w-inline-block">
                        <div>Contactar</div>
                      </a>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </section>
      <section class="uui-section_cta08">
        <div class="uui-page-padding-3">
          <div class="uui-container-small">
            <div class="uui-padding-vertical-xhuge-3">
              <div class="uui-text-align-center-3">
                <h2 class="uui-heading-medium-3 light">Comienza hoy</h2>
                <div class="uui-space-xsmall-3"></div>
                <div class="uui-text-size-large-3 light">Más de 162.000 mediciones de salud registradas</div>
                <div class="uui-space-medium-2"></div>
                <div class="uui-button-row-3 button-row-center is-reverse-mobile-landscape">
                  <div class="uui-button-wrapper-3 max-width-full-mobile-landscape-3">
                    <a href="https://www.iduam.com?subject=Solicitar+demo#contacto"
                      class="uui-button-secondary-gray w-inline-block">
                      <div>Solicitar demo</div>
                    </a>
                  </div>
                  <div class="uui-button-wrapper-3 max-width-full-mobile-landscape-3">
                    <a href="https://www.iduam.com?subject=Comenzar#contacto" class="uui-button w-inline-block">
                      <div>Comenzar</div>
                    </a>
                  </div>
                </div>
              </div>
              <div class="text-center d-flex justify-center mt-5" style="gap: 18px; margin: 0 auto;">
                <a href="https://www.youtube.com/@iduam6042" target="_blank">
                  <v-icon style="font-size: 40px; cursor: pointer;" color="#FFFF">fa-youtube</v-icon>
                </a>
                <a href="https://www.instagram.com/iduamapp/" target="_blank">
                  <v-icon style="font-size: 40px; cursor: pointer;" color="#FFFF">fa-instagram</v-icon>
                </a>
                <a href="https://www.facebook.com/appiduam" target="_blank">
                  <v-icon style="font-size: 40px; cursor: pointer;" color="#FFFF">fa-facebook</v-icon>
                </a>
                <a href="https://www.tiktok.com/@iduam.salud" target="_blank">
                  <img style="width: 45px; height: 45px; cursor: pointer;" src="../../assets/images/tiktok-white.png"
                    alt="">
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="lottie-animation-3" data-w-id="a6d569d0-c538-9fe8-89a5-10161d2f5daf" data-animation-type="lottie"
          data-src="../../assets/documents/Loop-basico.json" data-loop="1" data-direction="1" data-autoplay="1"
          data-is-ix2-target="0" data-renderer="svg" data-default-duration="0" data-duration="0"></div>
      </section>
      <Footer />
    </main>
  </template>
  
  <script>
  import Footer from '../../components/commons/Footer.vue'
  import Header from '../../components/commons/Header.vue'
  import VueSlickCarousel from 'vue-slick-carousel'
  import 'vue-slick-carousel/dist/vue-slick-carousel.css'
  // optional style for arrows & dots
  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
  
  export default {
    name: "Iduam600",
    components: { Footer, Header, VueSlickCarousel },
    data: () => ({
      responsive_carousel: [
        {
          "breakpoint": 1024,
          "settings": {
            "slidesToShow": 3,
            "slidesToScroll": 3,
            "infinite": true,
            "dots": true
          }
        },
        {
          "breakpoint": 600,
          "settings": {
            "slidesToShow": 2,
            "slidesToScroll": 2,
            "initialSlide": 2
          }
        },
        {
          "breakpoint": 480,
          "settings": {
            "slidesToShow": 1,
            "slidesToScroll": 1
          }
        }
      ],
      items1: [
        {
          question: '¿Qué funciones ofrece la iDUAM G-50?',
          answer: 'La smartband iDUAM G-50 mide ritmo cardíaco, presión arterial, SpO2, temperatura corporal, ECG, lectura de glucosa, mediciones de colesterol, ácido úrico; triglicéridos, índice de masa corporal, parámetros de sueño, lectura de Whatsapp, Lectura de Mail, Contestación de llamadas telefónicas.',
        },
        {
          question: '¿Es compatible con cualquier teléfono móvil?',
          answer: 'Sí, la iDUAM G-50 es compatible con dispositivos Android (versión 7.0 o superior) y iOS (versión 12.0 o superior).',
        },
        {
          question: '¿Cómo se conecta con la App de iDUAM?',
          answer: 'Solo debes descargar la App iDUAM desde Google Play o App Store, registrarte y vincular la pulsera a través de Bluetooth siguiendo las instrucciones en pantalla.',
        },
        {
          question: '¿Cómo se realiza un electrocardiograma (ECG)?',
          answer: 'Desde la App iDUAM selecciona la opción de ECG, coloca el dedo en el sensor de la pulsera y espera la medición. Los resultados se almacenarán automáticamente en tu historial.',
        },
        {
          question: '¿Qué hago si tengo problemas de conexión con la pulsera?',
          answer: '1. Asegúrate de que Bluetooth está activado en tu teléfono. 2. Reinicia la pulsera y el teléfono. 3. Verifica que la pulsera tiene suficiente batería. 4. Si el problema persiste, desinstala y vuelve a instalar la App iDUAM.',
        },
      ],
      items2: [
        {
          question: '¿Cuánto dura la batería?',
          answer: 'La batería de la iDUAM G-50 tiene una duración de hasta 5 días con uso continuo y puede recargarse completamente en aproximadamente 2 horas.',
        },
        {
          question: '¿Es resistente al agua?',
          answer: 'Sí, cuenta con certificación IP67, lo que la hace resistente a salpicaduras, sudor y polvo, pero no es apta para natación o inmersiones prolongadas.',
        },
        {
          question: '¿Necesito tener la App abierta para que funcione?',
          answer: 'No es necesario. La pulsera recopila datos de forma autónoma y los sincroniza con la App cuando está conectada.',
        },
        {
          question: '¿Puedo compartir mis mediciones con mi médico o familiares?',
          answer: 'Sí, puedes compartir tu historial de salud desde la App iDUAM o dar acceso a tu Red de Ayuda para que puedan recibir alertas y reportes de tu estado de salud.',
        },
        {
          question: '¿Dónde puedo comprar la iDUAM G-50?',
          answer: 'Puedes adquirirla directamente en nuestra tienda en línea o a través de distribuidores autorizados. Pago seguro con Webpay y tarjetas de crédito/débito.',
        },
      ],
    }),
    methods: {
  
    },
    mounted() {
      // Cargar el script de jQuery
      const script1 = document.createElement('script');
      script1.src = "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=67bdc556c384e19b2a97bae5";
      script1.type = 'text/javascript';
      script1.integrity = 'sha256-9/aliU8dGd2tb6OSsuzixeV4y/faTqgFtohetphbbj0=';
      script1.crossOrigin = 'anonymous';
      document.body.appendChild(script1);
  
      // Cargar el script de Webflow
      const script2 = document.createElement('script');
      script2.src = '../../assets/js/webflow.js';
      script2.type = 'text/javascript';
      document.body.appendChild(script2);
  
      const script3 = document.createElement('script');
      script3.type = 'text/javascript';
      script3.innerHTML = `!function (o, c) { 
          var n = c.documentElement, t = " w-mod-"; 
          n.className += t + "js", 
          ("ontouchstart" in o || o.DocumentTouch && c instanceof DocumentTouch) && 
          (n.className += t + "touch") 
        }(window, document);`;
      document.body.appendChild(script3);
  
  
    }
    // beforeDestroy() {
    //   if (typeof window !== "undefined") {
    //     window.removeEventListener("resize", this.onResize, { passive: true });
    //   }
    // },
    // mounted() {
    //   this.onResize();
    //   window.addEventListener("resize", this.onResize, { passive: true });
    // },
    // methods: {
    //   onResize() {
    //     this.isMobile = window.innerWidth < 992;
    //   },
    // },
  };
  </script>
  <style>
  @import "../../assets/css/normalize.css";
  @import "../../assets/css/webflow.css";
  @import "../../assets/css/iduam-86f9f2.webflow.css";
  
  
  @media (max-width:991px) and (min-width:768px) {
    html.w-mod-js:not(.w-mod-ix) [data-w-id="3b38d427-d68a-25e1-0a46-bfd5b8ec59b8"] {
      height: 0px;
    }
  }
  
  @media (max-width:767px) and (min-width:480px) {
    html.w-mod-js:not(.w-mod-ix) [data-w-id="3b38d427-d68a-25e1-0a46-bfd5b8ec59b8"] {
      height: 0px;
    }
  }
  
  @media (max-width:479px) {
    html.w-mod-js:not(.w-mod-ix) [data-w-id="3b38d427-d68a-25e1-0a46-bfd5b8ec59b8"] {
      height: 0px;
    }
  }
  </style>