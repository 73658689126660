import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"uui-navbar02_component w-nav",attrs:{"data-w-id":"3b38d427-d68a-25e1-0a46-bfd5b8ec59a3","data-animation":"default","data-collapse":"medium","data-duration":"400","data-easing":"ease","data-easing2":"ease","role":"banner"}},[_c('div',{staticClass:"uui-navbar02_container"},[_c('a',{staticClass:"uui-navbar02_logo-link w-nav-brand",on:{"click":function($event){return _vm.$router.push('/')}}},[_vm._m(0)]),_c('nav',{staticClass:"uui-navbar02_menu w-nav-menu",attrs:{"role":"navigation"}},[_vm._m(1),_c('div',{staticClass:"uui-navbar02_menu-right"},[_c('div',{staticClass:"uui-navbar02_button-wrapper"},[_vm._m(2),_c('div',{staticClass:"text-center d-flex justify-center",staticStyle:{"gap":"18px","margin":"0 auto"}},[_c('a',{attrs:{"href":"https://www.youtube.com/@iduam6042","target":"_blank"}},[_c(VIcon,{staticStyle:{"font-size":"32px","cursor":"pointer"},attrs:{"color":"#20A3FF"}},[_vm._v("fa-youtube")])],1),_c('a',{attrs:{"href":"https://www.instagram.com/iduamapp/","target":"_blank"}},[_c(VIcon,{staticStyle:{"font-size":"32px","cursor":"pointer"},attrs:{"color":"#20A3FF"}},[_vm._v("fa-instagram")])],1),_c('a',{attrs:{"href":"https://www.facebook.com/appiduam","target":"_blank"}},[_c(VIcon,{staticStyle:{"font-size":"32px","cursor":"pointer"},attrs:{"color":"#20A3FF"}},[_vm._v("fa-facebook")])],1),_vm._m(3)]),_vm._m(4)])])]),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"uui-logo_component-2"},[_c('img',{staticClass:"uui-logo_logotype-2",attrs:{"src":require("@/assets/images/Logo-1.svg"),"loading":"lazy","alt":"Untitled UI logotext"}}),_c('img',{staticClass:"uui-logo_image-2",attrs:{"src":"images/untitled-ui-logo.png","loading":"lazy","alt":"Logo"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"uui-navbar02_menu-left"},[_c('a',{staticClass:"uui-navbar02_link w-nav-link",attrs:{"href":"https://www.iduam.com/#productos"}},[_vm._v("Productos")]),_c('a',{staticClass:"uui-navbar02_link w-nav-link",attrs:{"href":"https://www.iduam.com/#precios"}},[_vm._v("Precios")]),_c('a',{staticClass:"uui-navbar02_link w-nav-link",attrs:{"href":"https://www.iduam.com/#podcast"}},[_vm._v("Podcast")]),_c('a',{staticClass:"uui-navbar02_link w-nav-link",attrs:{"href":"https://www.iduam.com/#casos"}},[_vm._v("Noticias")]),_c('div',{staticClass:"uui-navbar02_menu-dropdown w-dropdown",attrs:{"data-hover":"true","data-delay":"300","data-w-id":"3b38d427-d68a-25e1-0a46-bfd5b8ec59b3"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"uui-button-secondary-gray-7 show-tablet-2 w-inline-block",attrs:{"target":"_blank","href":"https://pacientes.iduam.com/"}},[_c('div',[_vm._v("Registrarse")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://www.tiktok.com/@iduam.salud","target":"_blank"}},[_c('img',{staticStyle:{"width":"34px","height":"34px","cursor":"pointer"},attrs:{"src":require("../../assets/images/logo-tiktok.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"uui-button w-inline-block",attrs:{"target":"_blank","href":"https://pacientes.iduam.com/"}},[_c('div',[_vm._v("Iniciar Sesión Paciente")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"uui-navbar02_menu-button w-nav-button"},[_c('div',{staticClass:"menu-icon_component-2"},[_c('div',{staticClass:"menu-icon_line-top-2"}),_c('div',{staticClass:"menu-icon_line-middle-2"},[_c('div',{staticClass:"menu-icon_line-middle-inner-2"})]),_c('div',{staticClass:"menu-icon_line-bottom-2"})])])
}]

export { render, staticRenderFns }